.top{
    background-color: #1b1e23;
}

h1{
    padding-top: 25px;
    padding-bottom: 0px;
    margin: 0px;
}

h4{
    margin: 0px;
}

ul{
    padding-left: 0px;
}

.content-row{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 15px;
}    

.product-row{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 15px;
}   

small{
    font-size: 85%;
    color: #cfcfcf;
}

p{
    max-width: 40em;
    text-align: left;
    padding-top: 5px;
}

.content-row p {
    padding-top: 5px;
    /* max-width: 30em;
    text-align: left; */
}

.logo
{
    padding: 5px;
    max-width: 20em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-title{
    color: #01c6cd
}

.landingJumbotron
{
  margin-bottom: 0rem;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.jumbotronTitle
{
  font-family: Gill Sans,Gill Sans MT,Calibri,sans-serif; 
  color: #00e265;
  align-items: normal;
  text-align: center;
  padding-bottom: 15px;
}

.jumbotronHeaders
{
  margin-bottom: 20px;
  margin-top: 20px;
}

.jumbotronHeader
{
  font-family: Gill Sans,Gill Sans MT,Calibri,sans-serif; 
  color: #00c4e3;
  align-items: normal;
  text-align: left;
}

.jumbotronCol
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center-col
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
}

.footer-text
{
    margin: 10px;
    text-align: center;
}

.buttongroup
{
    margin-top: 10px;
    margin-bottom: 10px;
}

.invalid-feedback{
    color: #f99
}

.text-muted{
    color: #ccc !important
}

a{
    color: #809fff
}

.dropdown-header
{
    color: white;
}

.dropdown-menu
{
    background-color: #212529;
}

.btn-link
{
    color: #809fff;
}

.btn-outline-primary
{
    color: #809fff;
    border-color: #809fff;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .open>.dropdown-toggle.btn-outline-primary {
    color: #fff;
    background-color: #809fff;
    border-color: #809fff; /*set the color you want here*/
}

.valid-feedback
{
    color: #84e19a
}

.border-primary
{
    border-color: #809fff !important
}